import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Card,
  Form,
  Input, Button,
  message,
  Upload
} from "antd";
import DashbordSidebar from "../../components/DashboardSidebar";
import { useLocation, useNavigate } from "react-router";
import { FaArrowLeftLong } from "react-icons/fa6";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { INSTRUCTOR, UPLOADS_URL } from "../../config/constants/api";
import { Put } from "../../config/api/put";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import { FaCamera } from "react-icons/fa";

const { TextArea } = Input;
dayjs.extend(customParseFormat);

const onFinishFailed = (errorInfo) => {
  console.log("Failed:", errorInfo);
};

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};
// const beforeUpload = (file) => {
//   const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
//   if (!isJpgOrPng) {
//     message.error("You can only upload JPG/PNG file!");
//   }
//   const isLt2M = file.size / 1024 / 1024 < 2;
//   if (!isLt2M) {
//     message.error("Image must smaller than 2MB!");
//   }
//   return isJpgOrPng && isLt2M;
// };
const beforeUpload = (file) => {
  const isImage = file.type.startsWith("image/");
  if (!isImage) {
    message.error("Invalid Uplaod, You can only upload image files!");
  }
  return isImage;
};

const InstructorsRiggersEdit = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.userToken);
  const [form] = Form.useForm();
  const [imageObject, setImageObject] = useState(null);
  const location = useLocation();
  const instructorDetails = location?.state?.instructorDetails;
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  // const handleChangepro = (info) => {
  //   if (info.file.status === "uploading") {
  //     setLoading(true);
  //     return;
  //   }
  //   if (info.file.status === "done") {
  //     getBase64(info.file.originFileObj, (url) => {
  //       setImageObject(info?.file?.originFileObj);
  //       setLoading(false);
  //       setImageUrl(url);
  //     });
  //   }
  // };
  const handleChangepro = (info) => {
    setLoading(true);
    getBase64(
      info?.fileList[info?.fileList?.length - 1]?.originFileObj,
      (url) => {
        setImageObject(
          info?.fileList[info?.fileList?.length - 1]?.originFileObj
        );
        setLoading(false);
        setImageUrl(url);
      }
    );
  };
  useEffect(() => {
    if (!instructorDetails) {
      navigate(-1);
    } else {
      form.setFieldsValue({
        fullName: instructorDetails?.fullName,
        designation: instructorDetails?.designation,
        description: instructorDetails?.description,
        cashappNo: instructorDetails?.cashappNo,
        venmoNo: instructorDetails?.venmoNo,
      });
      setImageUrl(UPLOADS_URL + instructorDetails?.image);
    }
  }, []);
  const onFinish = (values) => {
    console.log("Success:", values);
    const { fullName, designation, description, cashappNo, venmoNo } = values;
    const formValuesChanged = () => {
      return (
        values.fullName !== instructorDetails?.fullName ||
        values.designation !== instructorDetails?.designation ||
        values.description !== instructorDetails?.description ||
        values.cashappNo !== instructorDetails?.cashappNo ||
        values.venmoNo !== instructorDetails?.venmoNo ||
        imageObject
      );
    };
    if (formValuesChanged()) {
      let data = new FormData();
      if(values?.fullName){
        data.append("fullName", fullName);
      }
      if(values?.designation){
        data.append("designation", designation);
      }
      if(values?.description){
        data.append("description", description);
      }
      if(values?.cashappNo){
        data.append("cashappNo", cashappNo);
      }
      if(values?.venmoNo){
        data.append("venmoNo", venmoNo);
      }
      if(imageObject){
        data.append("image", imageObject);
      }
      Put(
        `${INSTRUCTOR.updateInstructor}${instructorDetails?._id}`,
        token,
        data, 
        null, 
        'multipart'
      )
        .then((response) => {
          if (response?.status) {
            swal("System Alert!", response?.message, "success");
            navigate(`/instructorsRiggers/${instructorDetails._id}`);
          }
        })
        .catch((err) => {
          console.log(err);
          const message = err?.response?.data?.message;
          if (message) {
            swal("Oops", message, "error");
          }
        });
    } else {
      swal("System Alert!", "No changes detected", "error");
      return;
    }
  };
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );
  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <div className="shop-page-main">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={24} lg={24} xl={24}>
                <div className="my-account-profile">
                  <section className="side-menu-parent">
                    <DashbordSidebar />
                    <div className="about-us-section">
                      <div className="bg-paren dashboard-right-card">
                        <Card className="details-card">
                          <div class="arrow-box">
                            <FaArrowLeftLong
                              className="arrow"
                              onClick={() => navigate(-1)}
                            />
                            <h3 className="main-heading">
                              Edit Instructor or Rigger
                            </h3>
                          </div>
                          <Row align="" gutter={24}>
                            <Col lg={17}>
                              <Form
                                className="row g-3"
                                name="basic"
                                layout="vertical"
                                initialValues={{
                                  remember: true,
                                }}
                                onFinish={onFinish}
                                autoComplete="off"
                                onFinishFailed={onFinishFailed}
                                form={form}
                              >
                                <Form.Item label="Full Name:" name="fullName">
                                  <Input
                                    size="large"
                                    placeholder="John Doe"
                                    className="web-input"
                                    style={{ maxWidth: "500px" }}
                                  />
                                </Form.Item>
                                <Form.Item
                                  label="Designation:"
                                  name="designation"
                                >
                                  <Input
                                    size="large"
                                    placeholder="AFF Instructor"
                                    className="web-input"
                                    style={{ maxWidth: "500px" }}
                                  />
                                </Form.Item>
                                <Form.Item
                                  label="Description"
                                  name="description"
                                >
                                  <TextArea
                                    rows={4}
                                    placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
                                    className="web-input"
                                    style={{ maxWidth: "500px" }}
                                  />
                                </Form.Item>
                                <h3 class="text-20">Tip Details:</h3>
                                <Form.Item
                                  placeholder="Tap to add"
                                  label="Cashapp#"
                                  name="cashappNo"
                                >
                                  <Input
                                    size="large"
                                    className="web-input"
                                    style={{ maxWidth: "500px" }}
                                  />
                                </Form.Item>
                                <Form.Item
                                  label="Venmo#"
                                  name="venmoNo"
                                  placeholder="Tap to add"
                                >
                                  <Input
                                    size="large"
                                    className="web-input"
                                    style={{ maxWidth: "500px" }}
                                  />
                                </Form.Item>
                                <div className="frame-group">
                                  <div className="">
                                    <Button
                                      type=""
                                      block
                                      size={"large"}
                                      style={{ marginBottom: "10px" }}
                                      className="web-btn"
                                      htmlType="submit"
                                    >
                                      Update
                                    </Button>
                                  </div>
                                  <div className="">
                                    <Button
                                      type=""
                                      block
                                      size={"large"}
                                      style={{
                                        marginBottom: "10px",
                                        background: "#b91717",
                                        color: "#fff",
                                      }}
                                      className="web-btn hover-white"
                                      onClick={() => {
                                        navigate(-1);
                                      }}
                                    >
                                      Cancel
                                    </Button>
                                  </div>
                                </div>
                              </Form>
                            </Col>
                            <Col lg={7}>
                              <>
                                <Upload
                                  name="avatar"
                                  listType="picture-circle"
                                  className="avatar-uploader"
                                  showUploadList={false}
                                  beforeUpload={beforeUpload}
                                  onChange={handleChangepro}
                                >
                                  {imageUrl ? (
                                    <img
                                      src={imageUrl}
                                      alt="avatar"
                                      style={{
                                        width: "100%",
                                        filter: "blur(1px)",
                                        maxHeight:"300px",
                                        maxWidth:"300px",
                                        borderRadius:"15px",
                                        objectFit:'cover',
                                        objectPosition:'center'
                                      }}
                                    />
                                  ) : (
                                    uploadButton
                                  )}
                                    <FaCamera
                                      style={{
                                        position: "absolute",
                                        color: "rgb(0,127,202)",
                                        fontSize: "25px",
                                      }}
                                    />
                                </Upload>
                              </>
                            </Col>
                          </Row>
                        </Card>
                      </div>
                    </div>
                  </section>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default InstructorsRiggersEdit;
