import { React, useState } from "react";
import WebButton from "../../components/Buttons/WebButton";
import notibell from "../../assets/noti-bell.png";
import './style.css'
import {
  Image
} from "antd";

const NotificationCard = (props) => {
  const [notiRead, setNotiRead] = useState(props.read);
  return (
    <div>
      <div className={`notificationWrapper ${notiRead ? "unread" : ""}`}>
        <div
          className="for-notification-flex"
          key={props.id}
        >
          <div className="for-notification-flex">
            <div className="notificationImageIcon">
              <Image
                preview={false}
                alt={"Failed to load image"}
                src={notibell}
                className=""
              />
            </div>
            <div className="flex-grow-1">
              <p className="notificationText">{props?.text}</p>
              <div className="dateTime">
                <p className="p-sm theme-text-color mb-0"> {props?.date}</p>
                <span className="mx-2">|</span>
                <p className="p-sm theme-text-color mb-0"> {props?.time}</p>
              </div>
            </div>
          </div>
          <div className="flex-shrink-0 text-end">
            <WebButton
              onClick={() => setNotiRead(!notiRead)}
              variant={notiRead ? "secondaryButton" : "primaryButton"}
              className="customButton2"
              text={notiRead ? "Mark as Read" : "Mark as Unread"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationCard;
