import { useEffect, useState } from "react";
import {
  Col,
  Row,
  Card,
  Typography, Form,
  Input,
  Button,
  Select
} from "antd";
import { useLocation, useNavigate, useParams } from "react-router";
import { careersData } from "../../components/Data/data";
import DashbordSidebar from "../../components/DashboardSidebar";
import { JOB } from "../../config/constants/api";
import { Put } from "../../config/api/put";
import { useSelector } from "react-redux";
import swal from "sweetalert";

const Calls = () => {
  const location = useLocation();
  const jobDetails = location?.state?.jobDetails;
  const token = useSelector((state) => state.user.userToken);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [initialData, setInitialData] = useState(null);

  useEffect(() => {
    if (!jobDetails) {
      navigate(-1);
    } else {
      form.setFieldsValue({
        jobTitle: jobDetails?.title,
        receivingEmail: jobDetails?.recEmail,
        city: jobDetails?.location?.city,
        street: jobDetails?.location?.street,
        state: jobDetails?.location?.state,
        jobRole: jobDetails?.jobRole,
        experience: jobDetails?.experience,
        minRange: jobDetails?.minSalary,
        maxRange: jobDetails?.maxSalary,
        responsibilities: jobDetails?.responsibilities,
        personalAttributes: jobDetails?.attributes,
        requiredSkills: jobDetails?.skills,
        certification: jobDetails?.certification,
        education: jobDetails?.education,
      });
      setInitialData({
        jobTitle: jobDetails?.title,
        receivingEmail: jobDetails?.recEmail,
        city: jobDetails?.location?.city,
        street: jobDetails?.location?.street,
        state: jobDetails?.location?.state,
        jobRole: jobDetails?.jobRole,
        experience: jobDetails?.experience,
        minRange: jobDetails?.minSalary,
        maxRange: jobDetails?.maxSalary,
        responsibilities: jobDetails?.responsibilities,
        personalAttributes: jobDetails?.attributes,
        requiredSkills: jobDetails?.skills,
        certification: jobDetails?.certification,
        education: jobDetails?.education,
      });
    }
  }, []);
  const validateExperience = (_, value) => {
    if (!value) {
      return Promise.reject("Please input Experience!");
    }

    const regex = /^\d+(-\d+)?$/; // Regular expression to match either a single number or a range
    if (!regex.test(value)) {
      return Promise.reject("Please enter a valid Experience or range!");
    }

    return Promise.resolve();
  };


  const onFinish = (values) => {
    const {
      jobTitle,
      receivingEmail,
      city,
      state,
      experience,
      minRange,
      maxRange,
      jobRole,
      responsibilities,
      education,
      certification,
      personalAttributes,
      requiredSkills,
      street,
    } = values;
    const formValuesChanged = () => {
      return (
        street !== initialData?.street ||
        state !== initialData?.state ||
        jobTitle !== initialData?.jobTitle ||
        receivingEmail !== initialData?.receivingEmail ||
        city !== initialData?.city ||
        experience !== initialData?.experience ||
        jobRole !== initialData?.jobRole ||
        minRange !== initialData?.minRange ||
        maxRange !== initialData?.maxRange ||
        JSON.stringify(responsibilities) !==
          JSON.stringify(initialData?.responsibilities) ||
        JSON.stringify(education) !== JSON.stringify(initialData?.education) ||
        JSON.stringify(certification) !==
          JSON.stringify(initialData?.certification) ||
        JSON.stringify(personalAttributes) !==
          JSON.stringify(initialData?.personalAttributes) ||
        JSON.stringify(requiredSkills) !==
          JSON.stringify(initialData?.requiredSkills)
      );
    };
    if (formValuesChanged()) {
      let data = {
        location: {
          street,
          city,
          state,
        },
        title: jobTitle,
        recEmail: receivingEmail,
        experience,
        minSalary: minRange,
        maxSalary: maxRange,
        jobRole,
        responsibilities,
        education,
        certification,
        attributes: personalAttributes,
        skills: requiredSkills,
      };

      Put(`${JOB.updateJob}${jobDetails?._id}`, token, data)
        .then((response) => {
          if (response?.status) {
            swal("System Alert!", response?.message, "success");
            navigate(`/careers/${jobDetails?._id}`);
          }
        })
        .catch((err) => {
          console.log(err);
          const message = err?.response?.data?.message;
          if (message) {
            swal("Oops!", message, "error");
          }
        });
    } else {
      swal("System Alert!", "No changes detected", "error");
      return;
    }
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };


  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <div className="shop-page-main">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={24} lg={24} xl={24}>
                <div className="my-account-profile">
                  <section className="side-menu-parent">
                    <DashbordSidebar />
                    <div className="about-us-section">
                      <div className="bg-paren">
                        <Card className="details-card for-tag-input">
                          <h3 className="heading-28">Edit Job</h3>

                          <Form
                            className="row g-3"
                            name="basic"
                            layout="vertical"
                            initialValues={{
                              remember: true,
                            }}
                            onFinish={onFinish}
                            autoComplete="off"
                            onFinishFailed={onFinishFailed}
                            form={form}
                          >
                            <Row style={{ width: "100%" }} gutter={[16, 16]}>
                              <Col lg={12} md={12} xs={24}>
                                <Form.Item label="Job Title" name="jobTitle">
                                  <Input
                                    size="large"
                                    placeholder="Enter Job Title"
                                    className="web-input"
                                  />
                                </Form.Item>
                              </Col>
                              <Col lg={12} md={12} xs={24}>
                                <Form.Item
                                  label="Resume Receiving Email"
                                  name="receivingEmail"
                                >
                                  <Input
                                    size="large"
                                    placeholder="Enter Resume Receiving Email"
                                    className="web-input"
                                  />
                                </Form.Item>
                              </Col>
                              <Col lg={12} md={12} xs={24}>
                                <Form.Item label="City" name="city">
                                  <Input
                                    size="large"
                                    placeholder="Enter City"
                                    className="web-input"
                                  />
                                </Form.Item>
                              </Col>
                              <Col lg={12} md={12} xs={24}>
                                <Form.Item label="State" name="state">
                                  <Input
                                    size="large"
                                    placeholder="Enter State"
                                    className="web-input"
                                  />
                                </Form.Item>
                              </Col>
                              <Col lg={12} md={12} xs={24}>
                                <Form.Item
                                  label="Street"
                                  name="street"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input Street",
                                    },
                                  ]}
                                >
                                  <Input
                                    size="large"
                                    placeholder="Enter Street"
                                    className="web-input"
                                  />
                                </Form.Item>
                              </Col>
                              <Col lg={12} md={12} xs={24}>
                                <Form.Item
                                  label="Experience"
                                  name="experience"
                                  rules={[
                                    {
                                      validator: validateExperience,
                                    },
                                  ]}
                                >
                                  <Input
                                    size="large"
                                    placeholder="Enter Experience"
                                    className="web-input"
                                  />
                                </Form.Item>
                              </Col>
                              <Col lg={12} md={12} xs={24}>
                                <Form.Item
                                  label="Salary Min Range"
                                  name="minRange"
                                >
                                  <Input
                                    size="large"
                                    placeholder="Enter Lower range"
                                    className="web-input"
                                  />
                                </Form.Item>
                              </Col>
                              <Col lg={12} md={12} xs={24}>
                                <Form.Item
                                  label="Salary Max range"
                                  name="maxRange"
                                >
                                  <Input
                                    size="large"
                                    placeholder="Enter Max range"
                                    className="web-input"
                                  />
                                </Form.Item>
                              </Col>
                              <Col lg={24} md={24} xs={24}>
                                <Form.Item label="Job Role" name="jobRole">
                                  <Input
                                    size="large"
                                    placeholder="Write here"
                                    className="web-input"
                                  />
                                </Form.Item>
                              </Col>
                              <Col lg={24} md={24} xs={24}>
                                <Form.Item
                                  label="Key Responsibilities & Accountabilities:"
                                  name="responsibilities"
                                >
                                  <Select
                                    mode="tags"
                                    style={{
                                      width: "100%",
                                    }}
                                    placeholder="Write here"
                                    className="tag-input"
                                  />
                                </Form.Item>
                              </Col>
                              <Col lg={24} md={24} xs={24}>
                                <Form.Item
                                  label="Education & Experience"
                                  name="education"
                                >
                                  <Select
                                    mode="tags"
                                    style={{
                                      width: "100%",
                                    }}
                                    placeholder="Write here"
                                    className="tag-input"
                                  />
                                </Form.Item>
                              </Col>
                              <Col lg={24} md={24} xs={24}>
                                <Form.Item
                                  label="Certification"
                                  name="certification"
                                >
                                  <Select
                                    mode="tags"
                                    style={{
                                      width: "100%",
                                    }}
                                    placeholder="Write here"
                                    className="tag-input"
                                  />
                                </Form.Item>
                              </Col>
                              <Col lg={24} md={24} xs={24}>
                                <Form.Item
                                  label="Personal Attributes"
                                  name="personalAttributes"
                                >
                                  <Select
                                    mode="tags"
                                    style={{
                                      width: "100%",
                                    }}
                                    placeholder="Write here"
                                    className="tag-input"
                                  />
                                </Form.Item>
                              </Col>
                              <Col lg={24} md={24} xs={24}>
                                <Form.Item
                                  label="Required Skills"
                                  name="requiredSkills"
                                >
                                  <Select
                                    mode="tags"
                                    style={{
                                      width: "100%",
                                    }}
                                    placeholder="Write here"
                                    className="tag-input"
                                  />
                                </Form.Item>
                              </Col>
                              <Col lg={24} md={24} xs={24}>
                                <div className="frame-group">
                     
                                  <div className="">
                                    <Button
                                      type=""
                                      htmlType="submit"
                                      block
                                      size={"large"}
                                      style={{ marginBottom: "10px" }}
                                      className="web-btn"
                                    >
                                      Update
                                    </Button>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </Card>
                      </div>
                    </div>
                  </section>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Calls;
