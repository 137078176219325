import { useEffect, useState } from "react";
import {
  Col,
  Row,
  Card,
  Typography,
  Divider, Form, Button,
  TimePicker
} from "antd";
import { useNavigate } from "react-router";
import DashbordSidebar from "../../components/DashboardSidebar";
import { Post } from "../../config/api/post";
import { AVAILABILITY } from "../../config/constants/api";
import { useSelector } from "react-redux";
import moment from "moment";
import swal from "sweetalert";
import { Get } from "../../config/api/get";
import { Delete } from "../../config/api/delete";
import { RiDeleteBin6Line } from "react-icons/ri";

const BusinessHoursManagement = () => {
  const token = useSelector((state) => state.user.userToken);
  const [availabilty, setAvailability] = useState(null);
  const profileDetails = useSelector((state)=>state.user.profileDetails)
  const navigate = useNavigate();

  const onFinish = (values) => {
    console.log("Success:", values);
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const getAvailability = () => {
    Get(AVAILABILITY.getMyAvailability, token)
      .then((response) => {
        setAvailability(response?.data);
      })
      .catch((err) => {
        console.log("Error Fetching Availability ", err);
        setAvailability(null);
      });
  };
  useEffect(() => {
    getAvailability();
  }, []);
  useEffect(() => {
    if (!profileDetails) {
      navigate("/create-dropzone-company");
    }
  }, []);
  const [additionalTimes, setAdditionalTimes] = useState([
    [null], // Monday
    [null], // Tuesday
    [null], // Wednesday
    [null], // Thursday
    [null], // Friday
    [null], // Saturday
    [null], // Sunday
  ]);
  const days = {
    0: "Monday",
    1: "Tuesday",
    2: "Wednesday",
    3: "Thursday",
    4: "Friday",
    5: "Saturday",
    6: "Sunday",
  };
  const handleDelete = (id) => {
    Delete(`${AVAILABILITY.deleteHours}${id}`, token)
      .then((response) => {
        if (response?.status) {
          getAvailability();
          swal("System Alert!", response?.message, "success");
        }
      })
      .catch((err) => {
        console.log(err);
        const message = err?.response?.data?.message;
        if (message) {
          swal("Oops!", message, "error");
        }
      });
  };

  const handleTimeChange = (dayIndex, index, time) => {
    const newTimes = [...additionalTimes[dayIndex]];
    newTimes[index] = time;
    const newAdditionalTimes = [...additionalTimes];
    newAdditionalTimes[dayIndex] = newTimes;
    setAdditionalTimes(newAdditionalTimes);
  };
  const handleUpdate = (dayIndex, index) => {
    if (
      additionalTimes[dayIndex][index] &&
      additionalTimes[dayIndex][index]
    ) {
      let data = {
        type: "COMPANY",
        from: moment(additionalTimes[dayIndex][index][0]?.$d).format(
          "HH:mm:ss"
        ),
        to: moment(additionalTimes[dayIndex][index][1]?.$d).format("HH:mm:ss"),
        day: days[dayIndex]?.toUpperCase(),
      };
      Post(AVAILABILITY.setHours, data, token)
        .then((response) => {
          if (response?.status) {
            getAvailability();
            swal("System Alert!", response?.message, "success");
          }
        })
        .catch((err) => {
          console.log(err);
          const message = err?.response?.data?.message;
          if (message) {
            swal("Oops!", message, "error");
          }
        });
    } else {
      return;
    }
  };
  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <div className="shop-page-main">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={24} lg={24} xl={24}>
                <div className="my-account-profile">
                  <section className="side-menu-parent">
                    <DashbordSidebar />
                    <div className="about-us-section">
                      <div className="bg-paren dashboard-right-card">
                        <Card className="details-card for-tag-input">
                          <h3 className="heading-28">
                            Business Hours Management
                          </h3>
                          <Form
                            className="row g-3"
                            name="basic"
                            layout="vertical"
                            initialValues={{
                              remember: true,
                            }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                          >
                            {[0, 1, 2, 3, 4, 5, 6].map((dayIndex) => (
                              <div key={dayIndex}>
                                {availabilty ? (
                                  availabilty
                                    .filter(
                                      (item) =>
                                        item.day ===
                                        days[dayIndex].toUpperCase()
                                    )
                                    .map((availabilityItem, index) => (
                                      <Row
                                        key={index}
                                        style={{ width: "100%" }}
                                        gutter={[16, 16]}
                                      >
                                        <Col lg={22} md={22} xs={24}>
                                          <div className="addtimeList">
                                            <Typography.Text className="heading-18">
                                              From: {availabilityItem.from} -
                                              To: {availabilityItem.to}
                                            </Typography.Text>
                                            <Button
                                              type="danger"
                                              className="delete-btn"
                                              onClick={() => {
                                                handleDelete(
                                                  availabilityItem._id
                                                );
                                              }}
                                            >
                                              <RiDeleteBin6Line />
                                            </Button>
                                          </div>
                                        </Col>
                                      </Row>
                                    ))
                                ) : (
                                  <p></p>
                                )}

                                {additionalTimes[dayIndex].map((_, index) => (
                                  <Row
                                    key={index}
                                    style={{ width: "100%" }}
                                    gutter={[16, 16]}
                                  >
                                    <Col lg={22} md={22} xs={24}>
                                      <Form.Item
                                        label={`${days[dayIndex]} Time`}
                                        name={`additionalTime_${dayIndex}_${index}`}
                                      >
                                        <div className="adding">
                                          <TimePicker.RangePicker
                                            onChange={(time) =>
                                              handleTimeChange(
                                                dayIndex,
                                                index,
                                                time
                                              )
                                            }
                                            className="web-input"
                                            style={{ maxWidth: "500px" }}
                                          />
                                          <Button
                                            className="web-btn-mini"
                                            onClick={() =>
                                              handleUpdate(dayIndex, index)
                                            }
                                          >
                                            Add
                                          </Button>
                                        </div>
                                      </Form.Item>
                                    </Col>
                                  </Row>
                                ))}
                              
                                <Divider />
                              </div>
                            ))}
                          </Form>
                        </Card>
                      </div>
                    </div>
                  </section>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default BusinessHoursManagement;
