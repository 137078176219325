import React, { useEffect, useState } from "react";
import { Col, Row, Card, Button, Spin } from "antd";
import DashbordSidebar from "../../components/DashboardSidebar";
import { instructorsData } from "../../components/Data/data";
import { useNavigate, useParams } from "react-router";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaEdit } from "react-icons/fa";
import { Get } from "../../config/api/get";
import { INSTRUCTOR, UPLOADS_URL } from "../../config/constants/api";
import { useSelector } from "react-redux";

const dateConverter = (date) => {
  const convertedDate = new Date(date);
  let options = { month: "long", day: "numeric" };
  let monthAndDate = convertedDate.toLocaleDateString("en-US", options);
  return monthAndDate;
};

const InstructorsRiggers = () => {
  const token = useSelector((state) => state.user.userToken);
  const navigate = useNavigate();
  const profileDetails = useSelector((state) => state.user.profileDetails);
  const [events, setEvents] = useState(instructorsData);
  const [instructors, setInstructors] = useState(null);
  const [loading, setLoading] = useState(true);
  const handleDelete = (eventId) => {
    const updatedEvents = events.filter((event) => event.id !== eventId);
    setEvents(updatedEvents);
  };
  const getMyInstructors = () => {
    setLoading(true);
    Get(INSTRUCTOR.getMyInstructors, token)
      .then((response) => {
        if (response?.status) {
          setInstructors(response?.data?.docs);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("Error Fetching Instructors", err);
        setLoading(false);
      });
  };
  useEffect(() => {
    if (!profileDetails) {
      navigate("/create-dropzone-company");
    }
  }, []);
  useEffect(() => {
    getMyInstructors();
  }, []);

  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <div className="shop-page-main">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={24} lg={24} xl={24}>
                <div className="my-account-profile">
                  <section className="side-menu-parent">
                    <DashbordSidebar />
                    <div className="about-us-section">
                      <div className="bg-paren dashboard-right-card">
                        <Card className="details-card">
                          <h3 className="heading-28">Instructors & Riggers</h3>
                          <div className="" style={{ textAlign: "end" }}>
                            <Button
                              type=""
                              style={{ marginBottom: "10px" }}
                              className="web-btn"
                              onClick={() =>
                                navigate(
                                  "/instructorsRiggers/instructorsRiggersAdd"
                                )
                              }
                            >
                              + Add
                            </Button>
                          </div>
                          {!loading ? (
                            <Row
                              justify="center"
                              style={{ alignItems: "baseline" }}
                              gutter={[16, 16]}
                            >
                              <Col lg={24}>
                                {Array.isArray(instructors) &&
                                instructors.length > 0 ? (
                                  instructors.map((instructorsData, index) => (
                                    <Card
                                      className="upcoming-cards"
                                      key={index}
                                    >
                                      <Row
                                        style={{ alignItems: "center" }}
                                        gutter={[16, 16]}
                                      >
                                        <Col lg={8}>
                                          <h3 className="text-20">Name</h3>
                                          <div className="jump-inner-detail">
                                            <div className="for-media-object instructorImg">
                                              <span>
                                                <img
                                                  src={
                                                    UPLOADS_URL +
                                                    instructorsData?.image
                                                  }
                                                  alt=""
                                                  className="img-fluid"
                                                  style={{ width: "100%" }}
                                                />
                                              </span>
                                              <div>
                                                <h6 className="instructor-name">
                                                  {instructorsData?.fullName}
                                                </h6>
                                                <span className="blue-text">
                                                  {instructorsData?.designation}
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </Col>
                                        <Col lg={16}>
                                          <h3 className="text-20">
                                            Description
                                          </h3>
                                          <div className="">
                                            <p className="web-p">
                                              {instructorsData?.description}
                                            </p>
                                          </div>
                                          <div>
                                            <Button
                                              type="link"
                                              className="web-btn"
                                              onClick={() =>
                                                navigate(
                                                  "/instructorsRiggers/" +
                                                    instructorsData._id
                                                )
                                              }
                                            >
                                              View Details
                                            </Button>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Card>
                                  ))
                                ) : (
                                  <p style={{textAlign:'center', fontSize:'22px', fontWeight:'bold' , margin:'50px 0'}}>No Instructors to show</p>

                                )}
                              </Col>
                            </Row>
                          ) : (
                            <Card>
                              <Col
                                xs={23}
                                md={21}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  margin: "150px 0px",
                                }}
                              >
                                <Spin />
                              </Col>
                            </Card>
                          )}
                        </Card>
                      </div>
                    </div>
                  </section>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default InstructorsRiggers;
