import { Col, Row, Typography, Image } from "antd";
import { useNavigate, useParams } from "react-router";
import { FaArrowLeftLong } from "react-icons/fa6";
import experienceflyImg from "../../assets/experiencefly-img.png";
import experienceflyImg2 from "../../assets/experiencefly-img2.png";
import plainimg from "../../assets/plane.png";
// import AboutUsHome from "../../views/homepage/aboutUsHome";
// const { Meta } = Card;
const { Title } = Typography;
const dateConverter = (date) => {
  const convertedDate = new Date(date);
  let options = { month: "long", day: "numeric" };
  let monthAndDate = convertedDate.toLocaleDateString("en-US", options);
  return monthAndDate;
};
const UpcomingEvents = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <Row style={{ width: "100%", justifyContent: "center" }}>
            <Col lg={24}>
              <div className="arrow-box">
                <FaArrowLeftLong
                  className="arrow"
                  onClick={() => navigate(-1)}
                />
                <h3 className="main-heading">About Us</h3>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <section className="experience-fly ant-experience-fly">
      <div className="ant-container-fluid">
        <Row justify="center" gutter={16}>
          <Col lg={20} md={20} xs={23}>
            <Row align="middle" gutter={16}>
              <Col lg={10}>
                <div className="experiencefly-imgbox ant-experiencefly-imgbox">
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={experienceflyImg}
                    className=""
                  />
                  <Image
                    preview={false}
                    alt={"Failed to load image"}
                    src={experienceflyImg2}
                    className=""
                  />
                </div>
              </Col>
              <Col lg={14}>
                <h6>Experience what it means to fly</h6>
                <h2>A Great Place to Receive Care</h2>
                <p className="web-p pb-3">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the when an unknown printer
                  took a galley of type and scrambled it to make a type specimen
                  book.
                  <br />
                  <br />
                  It has survived not only five centuries, but also the leap
                  into electronic typesetting, remaining essentially unchanged.
                  It was popularised in the 1960s with the release of Letraset
                  sheets containing Lorem Ipsum passages, and more recently with
                  desktop publishing software like Aldus PageMaker including
                  versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of
                  the printing and typesetting industry.
                </p>
                <a href="#_" className="web-btn ant-web-btn">
                  Read More
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="plainimg ant-plainimg">
      <Image
        preview={false}
        alt={"Failed to load image"}
        src={plainimg}
      />
      </div>
    </section>
      {/* <AboutUsHome /> */}
    </div>
  );
};

export default UpcomingEvents;
