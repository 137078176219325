import testiProfilePic from "../../assets/testi-profile.png";
import clientlogo1 from "../../assets/client-logo1.png";
import clientlogo2 from "../../assets/client-logo2.png";
import clientlogo3 from "../../assets/client-logo3.png";
import clientlogo4 from "../../assets/client-logo4.png";
import clientlogo5 from "../../assets/client-logo5.png";
import clientlogo6 from "../../assets/client-logo6.png";

import stayBookingimg from "../../assets/stay-bookingimg.png";
import dropcardimg from "../../assets/skydrive-img.png";
import reviewspro from "../../assets/reviews-pro.png";
import instructorspro from "../../assets/instructors-pro.png";
import myprofilepic from "../../assets/profileimg.png";
import flagimg from "../../assets/flag.png";
import glasses from "../../assets/glasses.png";
import helmet from "../../assets/helmet.png";
import skycamera from "../../assets/skycamera.png";
import gloves from "../../assets/gloves.png";
import sellerimg from "../../assets/seller-img.png";
import selfieImg from "../../assets/profileimg.png";
import idFrontImg from "../../assets/idfront.png";
import idBackImg from "../../assets/idback.png";
import licenseImg from "../../assets/license.png";
import { Link } from "react-router-dom";

export const companyProfileDate = [
  {
    id: 1,
    pic: (
      <img
        src={dropcardimg}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    address: "2762 Blvd Jean Paul 2",
    city: "Lomé",
    accountCurrency: "Lomé",
    country: "United States",
    video_URL_DZBriefing: "https://youtube.com/dummy-video-url",
    briefing_Description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis",
    companyTitle: "ABC Drop Zone Company",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar tempor. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis",
    selfie: <img src={selfieImg} alt="" className="img-fluid" />,
    idFront: (
      <img
        src={idFrontImg}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    idBack: (
      <img
        src={idBackImg}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    license: (
      <img
        src={licenseImg}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    beneficiaryName: "Mr Alferd",
    accountCurrency: "$ USD",
    accountNumber: "1111 1111 1111 1111",
    swiftNumber: "1234233344",
    bankcity: "Los Angeles",
    remittanceCountry: "142467473735737376378347",
    iban: "Enter IBAN",
    bankName: "ABC Bank Name",
    branchName: "ABC Branch",
    bankAddress: "ABC Address",
  },
];

export const dropzoneData = [
  {
    id: 1,
    pic: (
      <img
        src={dropcardimg}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    title: "Jump Switzerland Tickets",
    subtitle: "Tandem Jump from 13,000 Ft",
    price: "425.00",
    rating: 4,
    additionalDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.Lorem Ipsum is simply dummy text of the printing and typesetting industry. ...",
    time: "12:00 PM",
    date: "29 Sep 2023",
    checkIn: "Wed, May 25, 2023, From 2:00 PM",
    checkOut: "Fri, May 27, 2023, Until 11:00 AM",
    loctaion: "ABC road, 123 street New York",
    weather: "Sunny",
    jumptype: "Tandem",
  },
  {
    id: 2,
    pic: (
      <img
        src={dropcardimg}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    title: "Tandem Jump Mission Beach",
    subtitle: "Tandem Jump from 13,000 Ft",
    price: "425.00",
    rating: 4,
    additionalDetails:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since..Lorem Ipsum is simply dummy text of the printing and typesetting industry. ..",
    time: "12:00 PM",
    date: "29 Sep 2023",
    checkIn: "Wed, May 25, 2023, From 2:00 PM",
    checkOut: "Fri, May 27, 2023, Until 11:00 AM",
    loctaion: "ABC road, 123 street New York",
    weather: "Sunny",
    jumptype: "Tandem",
  },
];

export const reviews = [
  {
    id: 1,
    reviewsProfile: <img src={reviewspro} alt="" className="img-fluid" />,
    reviewName: "James Anderson",
    rating: 5,
    reviewDiscretion:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  },
  {
    id: 2,
    reviewsProfile: <img src={reviewspro} alt="" className="img-fluid" />,
    rating: 4,
    reviewName: "James Anderson",
    reviewDiscretion:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
  },
];

export const dropzoneLogsData = [
  {
    id: 1,
    pic: (
      <img
        src={dropcardimg}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    status: "UPCOMING",
    statusText: "Upcoming",
    bookingId: "#25874",
    customername: "Jeremy",
    dropZoneName: "Tandem Jump",
    cost: "$450.00",
    title: "Jump Switzerland Tickets",
    subtitle: "Tandem Jump from 13,000 Ft",
    additionalDetails:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod Lorem ipsum dolor sitamet, consectetur adipiscing elit. Aenean euismod Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    price: "425.00",
    loctaion: "ABC road, 123 street New York",
    weather: "Sunny",
    jumptype: "Tandem",
    time: "12:00 PM",
    date: "29 Sep 2023",
  },
  {
    id: 2,
    pic: (
      <img
        src={dropcardimg}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    status: "ONGOING",
    statusText: "Ongoing",
    bookingId: "#25874",
    customername: "Jeremy",
    dropZoneName: "Tandem Jump",
    subtitle: "Tandem Jump from 13,000 Ft",
    cost: "$450.00",
    title: "Elegantly Sobha Hartlands",
    additionalDetails:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod Lorem ipsum dolor sitamet, consectetur adipiscing elit. Aenean euismod Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    loctaion: "ABC road, 123 street New York",
    weather: "Sunny",
    jumptype: "Tandem",
    price: "425.00",
    time: "12:00 PM",
    date: "29 Sep 2023",
  },
  {
    id: 3,
    pic: (
      <img
        src={dropcardimg}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    status: "UPCOMING",
    statusText: "Upcoming",
    bookingId: "#25874",
    customername: "Jeremy",
    dropZoneName: "Tandem Jump",
    subtitle: "Tandem Jump from 13,000 Ft",
    cost: "$450.00",
    title: "Elegantly Sobha Hartlands",
    additionalDetails:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod Lorem ipsum dolor sitamet, consectetur adipiscing elit. Aenean euismod Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    loctaion: "ABC road, 123 street New York",
    weather: "Sunny",
    jumptype: "Tandem",
    price: "425.00",
    time: "12:00 PM",
    date: "29 Sep 2023",
  },
  {
    id: 4,
    pic: (
      <img
        src={dropcardimg}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    status: "COMPLETED",
    statusText: "Completed",
    bookingId: "#25874",
    customername: "Jeremy",
    dropZoneName: "Tandem Jump",
    subtitle: "Tandem Jump from 13,000 Ft",
    cost: "$450.00",
    title: "Elegantly Sobha Hartlands",
    additionalDetails:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod Lorem ipsum dolor sitamet, consectetur adipiscing elit. Aenean euismod Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
    loctaion: "ABC road, 123 street New York",
    weather: "Sunny",
    jumptype: "Tandem",
    price: "425.00",
    time: "12:00 PM",
    date: "29 Sep 2023",
  },
];

export const upcomingEventsData = [
  {
    id: 1,
    pic: (
      <img
        src={dropcardimg}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    date: "2024-02-05T00:00:00.000+00:00",
    title:
      "Kirk Verner Jeana Billings – 20-ways at Skydive City! USPA or National Aero Club Membership required. Not Open to the Public. .",
    description: (
      <>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the when an unknown printer took a galley of type and scrambled it
        to make a type specimen book.It has survived not only five centuries,
        but also the leap into electronic typesetting, remaining essentially
        unchanged. <br></br>
        <br></br>
        It has survived not only five centuries, but also the leap into
        electronic typesetting, remaining essentially unchanged. It was
        popularised in the 1960s with the release of Letraset sheets containing
        Lorem Ipsum passages, and more recently with desktop publishing software
        like Aldus PageMaker including versions of Lorem Ipsum.
      </>
    ),
  },
  {
    id: 2,
    pic: (
      <img
        src={dropcardimg}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    date: "2024-04-09T00:00:00.000+00:00",
    title:
      "Kirk Verner Jeana Billings – 20-ways at Skydive City! USPA or National Aero Club Membership required. Not Open to the Public. .",
    description: (
      <>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the when an unknown printer took a galley of type and scrambled it
        to make a type specimen book.It has survived not only five centuries,
        but also the leap into electronic typesetting, remaining essentially
        unchanged. <br></br>
        <br></br>
        It has survived not only five centuries, but also the leap into
        electronic typesetting, remaining essentially unchanged. It was
        popularised in the 1960s with the release of Letraset sheets containing
        Lorem Ipsum passages, and more recently with desktop publishing software
        like Aldus PageMaker including versions of Lorem Ipsum.
      </>
    ),
  },
  {
    id: 3,
    pic: (
      <img
        src={dropcardimg}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    date: "2024-02-05T00:00:00.000+00:00",
    title:
      "Kirk Verner Jeana Billings – 20-ways at Skydive City! USPA or National Aero Club Membership required. Not Open to the Public. .",
    description: (
      <>
        Lorem Ipsum is simply dummy text of the printing and typesetting
        industry. Lorem Ipsum has been the industry's standard dummy text ever
        since the when an unknown printer took a galley of type and scrambled it
        to make a type specimen book.It has survived not only five centuries,
        but also the leap into electronic typesetting, remaining essentially
        unchanged. <br></br>
        <br></br>
        It has survived not only five centuries, but also the leap into
        electronic typesetting, remaining essentially unchanged. It was
        popularised in the 1960s with the release of Letraset sheets containing
        Lorem Ipsum passages, and more recently with desktop publishing software
        like Aldus PageMaker including versions of Lorem Ipsum.
      </>
    ),
  },
];

export const myprofiledata = {
  fullname: "Jake Dawson",
  profilePicUser: <img src={myprofilepic} alt="" className="img-fluid" />,
  email: "jake@sample.com",
  phoneNumber: "+1 844-219-0009",
  location: "ABC Location, Town, City, Country",
  gender: "Male",
  dob: "Jan 28, 1998",
  signWaiver: "ABC Waiver.pdf",
  flag: <img src={flagimg} alt="" className="img-fluid" />,
};

export const notifications = [
  {
    id: 1,
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    date: "Dec 19, 2022",
    time: "02:00 PM",
  },
  {
    id: 2,
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry and typesetting industry.",
    date: "Dec 19, 2022",
    time: "01:40 PM",
  },
  {
    id: 3,
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    date: "Dec 19, 2022",
    time: "02:00 PM",
  },
  {
    id: 4,
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry and typesetting industry.",
    date: "Dec 19, 2022",
    time: "01:40 PM",
  },
  {
    id: 5,
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry and typesetting industry.",
    date: "Dec 19, 2022",
    time: "01:40 PM",
  },
  {
    id: 6,
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry and typesetting industry.",
    date: "Dec 19, 2022",
    time: "01:40 PM",
  },
  {
    id: 7,
    text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry and typesetting industry.",
    date: "Dec 19, 2022",
    time: "01:40 PM",
  },
];

export const paymentData = [
  {
    id: 1,
    dropZoneID: "#25874",
    dropZoneName: "Drop Zone Name",
    customername: "Jeremy Martin",
    skydiveType: "Tandem",
    amountPaid: "$224.00",
    commission: "$224.00",
  },
  {
    id: 2,
    dropZoneID: "#25874",
    dropZoneName: "Drop Zone Name",
    customername: "Jeremy Martin",
    skydiveType: "Tandem",
    amountPaid: "$224.00",
    commission: "$224.00",
  },
  {
    id: 3,
    dropZoneID: "#25874",
    dropZoneName: "Drop Zone Name",
    customername: "Jeremy Martin",
    skydiveType: "Tandem",
    amountPaid: "$224.00",
    commission: "$224.00",
  },
];

export const careersData = [
  {
    id: 1,
    jobname: "ABC Title1",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean",
    location: "ABC road, 123 street New York",
    resume: "careers@domainname.com",
    experience: "3-5 years",
    postedDate: "May 20, 2023",
    joblocation: "California",
    salaryRange: "20k-25k",
    responsibilities: [
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo.",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo.",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo.",
    ],
    education_experience: [
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo.",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo.",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo.",
    ],
    certification: [
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo.",
    ],
    skills: [
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo.",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo.",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo.",
    ],
  },
  {
    id: 2,
    jobname: "ABC Title1",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean",
    location: "ABC road, 123 street New York",
    resume: "careers@domainname.com",
    experience: "3-5 years",
    postedDate: "May 20, 2023",
    joblocation: "California",
    salaryRange: "20k-25k",
    responsibilities: [
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo.",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo.",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo.",
    ],
    education_experience: [
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo.",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo.",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo.",
    ],
    certification: [
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo.",
    ],
    skills: [
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo.",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo.",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo.",
    ],
  },
  {
    id: 3,
    jobname: "ABC Title1",
    description:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean",
    location: "ABC road, 123 street New York",
    resume: "careers@domainname.com",
    experience: "3-5 years",
    postedDate: "May 20, 2023",
    joblocation: "California",
    salaryRange: "20k-25k",
    responsibilities: [
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo.",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo.",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo.",
    ],
    education_experience: [
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo.",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo.",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo.",
    ],
    certification: [
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo.",
    ],
    skills: [
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo.",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo.",
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo.",
    ],
  },
];

export const instructorsData = [
  {
    id: 1,
    pic: (
      <img
        src={instructorspro}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    instructorName: "John Doe1",
    instructortitle: "(Instructor)",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry",
    cashapp: "123456789",
    venmo: "123456789",
  },
  {
    id: 2,
    pic: (
      <img
        src={instructorspro}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    instructorName: "John Doe2",
    instructortitle: "(Instructor)",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry",
    cashapp: "123456789",
    venmo: "123456789",
  },
  {
    id: 3,
    pic: (
      <img
        src={instructorspro}
        alt=""
        className="img-fluid"
        style={{ width: "100%" }}
      />
    ),
    instructorName: "John Doe3",
    instructortitle: "(Instructor)",
    description:
      "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry",
    cashapp: "123456789",
    venmo: "123456789",
  },
];


// export const notifications = [
//   {
//     id: 1,
//     text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
//     date: "Dec 19, 2022",
//     time: "02:00 PM",
//   },
//   {
//     id: 2,
//     text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry and typesetting industry.",
//     date: "Dec 19, 2022",
//     time: "01:40 PM",
//   },
//   {
//     id: 3,
//     text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
//     date: "Dec 19, 2022",
//     time: "02:00 PM",
//   },
//   {
//     id: 4,
//     text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry and typesetting industry.",
//     date: "Dec 19, 2022",
//     time: "01:40 PM",
//   },
//   {
//     id: 5,
//     text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry and typesetting industry.",
//     date: "Dec 19, 2022",
//     time: "01:40 PM",
//   },
//   {
//     id: 6,
//     text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry and typesetting industry.",
//     date: "Dec 19, 2022",
//     time: "01:40 PM",
//   },
//   {
//     id: 7,
//     text: "Lorem Ipsum is simply dummy text of the printing and typesetting industry and typesetting industry.",
//     date: "Dec 19, 2022",
//     time: "01:40 PM",
//   },
// ];

