import { useEffect, useState } from "react";
import {
  Col,
  Row,
  Card,
  Typography,
  Divider, Button,
  Spin
} from "antd";
import { useNavigate, useParams } from "react-router";
import { careersData } from "../../components/Data/data";
import DashbordSidebar from "../../components/DashboardSidebar";
import { Get } from "../../config/api/get";
import { Delete } from "../../config/api/delete";
import { JOB } from "../../config/constants/api";
import { extractDate } from "../../config/helpers";
import { useSelector } from "react-redux";
import swal from "sweetalert";


const Calls = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.userToken);
  const { id } = useParams();
  const [jobDetails, setJobDetails] = useState(null);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    getJobDetails();
  }, []);
  const getJobDetails = () => {
    setLoading(true)
    Get(`${JOB.getJob}${id}`, token)
      .then((response) => {
        if (response?.status) {
          setJobDetails(response?.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log("Error fetching Job Details ", err);
        setLoading(false);
      });
  };
  const handleDelete = () => {
    Delete(`${JOB.deleteJob}${id}`, token)
      .then((response) => {
        if (response?.status) {
          swal("System Alert!", response?.message, "success");
          navigate("/careers");
        }
      })
      .catch((err) => {
        console.log(err);
        const message = err?.response?.data?.message;
        if (message) {
          swal("Oops", message, "error");
        }
      });
  };
  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <div className="shop-page-main">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={24} lg={24} xl={24}>
                <div className="my-account-profile">
                  <section className="side-menu-parent">
                    <DashbordSidebar />
                    <div className="about-us-section">
                      <div className="bg-paren">
                        {!loading ? (
                          <Card className="details-card">
                            <h3 className="heading-28">Job Details</h3>
                            <Row align="middle" gutter={16}>
                              <Col lg={10}>
                                <div className="">
                                  <div>
                                    <h4 className="text-26">
                                      {jobDetails?.title}
                                    </h4>
                                  </div>
                                  <div>
                                    <h4 className="text-16">Location:</h4>
                                    <p className="web-p">
                                      {jobDetails?.location?.street +
                                        " " +
                                        jobDetails?.location?.state +
                                        " " +
                                        jobDetails?.location?.city}
                                    </p>
                                  </div>
                                </div>
                              </Col>
                              <Col lg={10}>
                                <div className="">
                                  <div>
                                    <h4 className="text-26">Apply Now</h4>
                                  </div>
                                  <div>
                                    <h4 className="text-16">
                                      Send your resume at:
                                    </h4>
                                    <p className="web-p">
                                      {jobDetails?.recEmail}
                                    </p>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Divider />
                            <Row align="middle" gutter={16}>
                              <Col lg={10}>
                                <div className="">
                                  <div>
                                    <h4 className="text-16">Experience:</h4>
                                    <p className="web-p">
                                      {jobDetails?.experience + " years"}
                                    </p>
                                  </div>
                                  <div>
                                    <h4 className="text-16">Posted:</h4>
                                    <p className="web-p">
                                      {extractDate(jobDetails?.createdAt)}
                                    </p>
                                  </div>
                                  <div>
                                    <h4 className="text-16">Location:</h4>
                                    <p className="web-p">
                                      {jobDetails?.location?.state}
                                    </p>
                                  </div>
                                  <div>
                                    <h4 className="text-16">Salary Range:</h4>
                                    <p className="web-p">
                                      {jobDetails?.minSalary +
                                        "-" +
                                        jobDetails?.maxSalary}
                                    </p>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Divider />
                            <Row align="middle" gutter={16}>
                              <Col lg={18}>
                                <div className="">
                                  <div>
                                    <h4 className="text-16">
                                      Key Responsibilities & Accountabilities:
                                    </h4>
                                    {jobDetails?.responsibilities.map(
                                      (item, index) => {
                                        return (
                                          <p className="web-p" key={index}>
                                            {item}
                                          </p>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Divider />
                            <Row align="middle" gutter={16}>
                              <Col lg={18}>
                                <div className="">
                                  <div>
                                    <h4 className="text-16">
                                      Education & Experience
                                    </h4>
                                    {jobDetails?.education.map(
                                      (item, index) => {
                                        return (
                                          <p className="web-p" key={index}>
                                            {item}
                                          </p>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Divider />
                            <Row align="middle" gutter={16}>
                              <Col lg={18}>
                                <div className="">
                                  <div>
                                    <h4 className="text-16">certification</h4>
                                    {jobDetails?.certification.map(
                                      (item, index) => {
                                        return (
                                          <p className="web-p" key={index}>
                                            {item}
                                          </p>
                                        );
                                      }
                                    )}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Divider />
                            <Row align="middle" gutter={16}>
                              <Col lg={18}>
                                <div className="">
                                  <div>
                                    <h4 className="text-16">Required Skills</h4>
                                    {jobDetails?.skills.map((item, index) => {
                                      return (
                                        <p className="web-p" key={index}>
                                          {item}
                                        </p>
                                      );
                                    })}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Divider />
                            <Row align="middle" gutter={16}>
                              <Col lg={18}>
                                <div className="frame-group">
                                  <div className="">
                                    <Button
                                      type=""
                                      block
                                      size={"large"}
                                      style={{
                                        marginBottom: "10px",
                                        background: "#b91717",
                                      }}
                                      className="web-btn2"
                                      onClick={handleDelete}
                                    >
                                      Delete
                                    </Button>
                                  </div>
                                  <div className="">
                                    <Button
                                      type=""
                                      block
                                      size={"large"}
                                      style={{ marginBottom: "10px" }}
                                      className="web-btn"
                                      onClick={() =>
                                        navigate(
                                          `/careers/editcareers/${jobDetails?._id}`,
                                          {
                                            state: { jobDetails },
                                          }
                                        )
                                      }
                                    >
                                      Edit
                                    </Button>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </Card>
                        ) : (
                          <Card>
                            <Col
                              xs={23}
                              md={21}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "150px 0px",
                              }}
                            >
                              <Spin />
                            </Col>
                          </Card>
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Calls;
