import React, { useEffect, useState } from "react";
import { Col, Row, Card, Button, Spin } from "antd";
import DashbordSidebar from "../../components/DashboardSidebar";
import { upcomingEventsData } from "../../components/Data/data";
import { useNavigate, useParams } from "react-router";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaEdit } from "react-icons/fa";
import { Get } from "../../config/api/get";
import { Delete } from "../../config/api/delete";
import { EVENT } from "../../config/constants/api";
import { useSelector } from "react-redux";
import swal from "sweetalert";

const dateConverter = (date) => {
  const convertedDate = new Date(date);
  let options = { month: "long", day: "numeric", timeZone: "UTC" };
  let monthAndDate = convertedDate.toLocaleDateString("en-US", options);
  return monthAndDate;
};

const UpcomingEvents = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.userToken);
  const profileDetails = useSelector((state) => state.user.profileDetails);
  const [upcomingEvents, setUpcomingEvents] = useState(null);
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => state.user.userData);

  // Function to delete event
  const handleDelete = (eventId) => {
    Delete(`${EVENT.deleteEvent}${eventId}`, token)
      .then((response) => {
        if (response?.status) {
          swal("System Alert!", response?.message, "success");
          getUpcomingEvents();
        }
      })
      .catch((err) => {
        console.log("Error ", err);
        const message = err?.response?.data?.message;
        if (message) {
          swal("Error", message, "error");
        }
      });
  };
  useEffect(() => {
    if (!profileDetails) {
      navigate("/create-dropzone-company");
    }
  }, []);
  const getUpcomingEvents = () => {
    setLoading(true)
    Get(EVENT.getupcomingevents, token, {
      companyId: user._id,
    })
      .then((response) => {
        if (response?.status) {
          setUpcomingEvents(response?.data?.docs);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("Error Fetching Events", err);
      });
  };
  useEffect(() => {
    getUpcomingEvents();
  }, []);

  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <div className="shop-page-main">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={24} lg={24} xl={24}>
                <div className="my-account-profile">
                  <section className="side-menu-parent">
                    <DashbordSidebar />
                    <div className="about-us-section">
                      <div className="bg-paren dashboard-right-card">
                        <Card className="details-card">
                          <h3 className="heading-28">Upcoming Events</h3>
                          <div className="" style={{ textAlign: "end" }}>
                            <Button
                              type=""
                              style={{ marginBottom: "10px" }}
                              className="web-btn"
                              onClick={() =>
                                navigate("/upcomingEvents/createEvents")
                              }
                            >
                              Create Events
                            </Button>
                          </div>
                          {!loading ? (
                            <Row justify="center">
                              {upcomingEvents && upcomingEvents.length > 0 ? (
                                <Col lg={24}>
                                  {upcomingEvents.map(
                                    (upcomingEvent, index) => (
                                      <Card
                                        className="upcoming-cards"
                                        key={index}
                                      >
                                        <Row align="middle" gutter={24}>
                                          <Col xxl={6} xl={24} lg={5} sm={24}>
                                            <div
                                              className=" event-date-box"
                                              style={{
                                                textAlign: "center",
                                                marginRight: "10px",
                                              }}
                                            >
                                              <h6>
                                                {dateConverter(
                                                  upcomingEvent.date
                                                )
                                                  .split(" ")
                                                  .map((part, index) => (
                                                    <React.Fragment key={index}>
                                                      {index > 0 && <br />}{" "}
                                                      {part}
                                                    </React.Fragment>
                                                  ))}
                                              </h6>
                                            </div>
                                          </Col>
                                          <Col xxl={18} xl={24} lg={24} sm={24}>
                                            <h5 className="text-22-urb">
                                              {upcomingEvent.title}
                                            </h5>
                                            <p className="web-p">
                                              {upcomingEvent.description}
                                            </p>
                                            <Button
                                              type="link"
                                              className="web-btn"
                                              onClick={() =>
                                                navigate(
                                                  "/upcomingEvents/" +
                                                    upcomingEvent._id
                                                )
                                              }
                                            >
                                              View Details
                                            </Button>
                                            <div className="delete-and-edit">
                                              <Button
                                                type="danger"
                                                className="edit-btn"
                                                style={{ padding: "5px" }}
                                                onClick={() =>
                                                  navigate(
                                                    `/upcomingEvents/upcomingEventsEdit/${upcomingEvent._id}`,
                                                    { state: { upcomingEvent } }
                                                  )
                                                }
                                              >
                                                <FaEdit />
                                              </Button>
                                              <Button
                                                type="danger"
                                                className="delete-btn"
                                                onClick={() =>
                                                  handleDelete(
                                                    upcomingEvent._id
                                                  )
                                                }
                                              >
                                                <RiDeleteBin6Line />
                                              </Button>
                                            </div>
                                          </Col>
                                        </Row>
                                      </Card>
                                    )
                                  )}
                                </Col>
                              ) : (
                                <p
                                  style={{
                                    fontSize: "22px",
                                    fontWeight: "bold",
                                  }}
                                >
                                  No Upcoming Events to show
                                </p>
                              )}
                            </Row>
                          ) : (
                            <Card>
                              <Col
                                xs={23}
                                md={21}
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  margin: "150px 0px",
                                }}
                              >
                                <Spin />
                              </Col>
                            </Card>
                          )}
                        </Card>
                      </div>
                    </div>
                  </section>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default UpcomingEvents;
