import { BrowserRouter, Routes, Route } from "react-router-dom";

// import AdminAuthCheck from "../../components/AuthCheck/AdminAuthCheck";
import ClientLayout from "../../components/ClientLayout";
import CreateAccount from "../../views/createAccount";
import Login from "../../views/login";
import ForgetPassword1 from "../../views/forget-password-1";
import ForgetPassword2 from "../../views/forget-password-2";
import ForgetPassword3 from "../../views/forget-password-3";
import AboutUs from "../../views/aboutUs";
import PrivacyPolicy from "../../views/privacyPolicy";
import TermsConditions from "../../views/termsConditions";
import ProtectedRoute from "../../views/protectedRoute";
import Profile from "../../views/profile";
import EditProfile from "../../views/profile/editProfile";
import ChangePassword from "../../views/profile/changePassword";
import Help from "../../views/help";
import CreateDropzoneCompany from "../../views/create-dropzone-company";
import DropzoneCompanyFormView from "../../views/dropzone-company-form-view";
import DropzoneCompanyFormEdit from "../../views/dropzone-company-form-view/dropzone-company-form-edit";
import Dashboard from "../../views/dashboard";
import DropZoneListing from "../../views/dropZoneListing";
import DropzoneDetails from "../../views/dropZoneListing/dropzoneDetails";
import AddDropZone from "../../views/dropZoneListing/addDropZone";
import EditDropZone from "../../views/dropZoneListing/editDropZone";
import PaymentLogs from "../../views/paymentLogs";
import DropZoneLogs from "../../views/dropZoneLogs";
import DropZoneLogDetails from "../../views/dropZoneLogs/dropZoneLogDetails";
import Careers from "../../views/careers";
import CareersDetails from "../../views/careers/careersDetails";
import CreateJob from "../../views/careers/createJob";
import Editcareers from "../../views/careers/editcareers";
import BusinessHoursManagement from "../../views/businessHoursManagement";
import PricingManagement from "../../views/pricingManagement";
import UpcomingEvents from "../../views/upcomingEvents";
import UpcomingEventsDetails from "../../views/upcomingEvents/upcomingEventsDetails";
import UpcomingEventsEdit from "../../views/upcomingEvents/upcomingEventsEdit";
import CreateEvents from "../../views/upcomingEvents/createEvents";
import InstructorsRiggers from "../../views/instructorsRiggers";
import InstructorsRiggersDetails from "../../views/instructorsRiggers/instructorsRiggersDetails";
import InstructorsRiggersEdit from "../../views/instructorsRiggers/instructorsRiggersEdit";
import InstructorsRiggersAdd from "../../views/instructorsRiggers/instructorsRiggersAdd";
import Notifications from "../../views/notifications/";
// import Notifications from "../../views/notifications";

const MyRouter = () => {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
      <Routes>
        <Route
          path="/createAccount"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <CreateAccount />
            </ClientLayout>
          }
        />
        <Route
          path="/login"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <Login />
            </ClientLayout>
          }
        />
        <Route
          path="/forget-password-1"
          index
          element={
            
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <ForgetPassword1 />
              </ClientLayout>
            
          }
        />
        <Route
          path="/forget-password-2"
          index
          element={
           
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <ForgetPassword2 />
              </ClientLayout>
          
          }
        />
        <Route
          path="/forget-password-3"
          index
          element={
           
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <ForgetPassword3 />
              </ClientLayout>
          
          }
        />
        <Route
          path="/aboutUs"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <AboutUs />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/privacyPolicy"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <PrivacyPolicy />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/termsConditions"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
                >
                <TermsConditions />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile"
          index
          element={
            <ProtectedRoute>
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
              >
              <Profile />
            </ClientLayout>
              </ProtectedRoute>
          }
        />
        <Route
          path="/editProfile"
          index
          element={
            <ProtectedRoute>
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
              >
              <EditProfile />
            </ClientLayout>
              </ProtectedRoute>
          }
        />
        <Route
          path="/changePassword"
          index
          element={
            <ProtectedRoute>
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
              >
              <ChangePassword />
            </ClientLayout>
              </ProtectedRoute>
          }
        />

        <Route
          path="/help"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <Help />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/create-dropzone-company"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <CreateDropzoneCompany />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dropzone-company-form-view"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <DropzoneCompanyFormView />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dropzone-company-form-edit"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <DropzoneCompanyFormEdit />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
              >
                <Dashboard />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dropZoneListing"
          index
          element={
            <ProtectedRoute>
              <ClientLayout
                head={{ title: "Dashboard", description: "Some Description." }}
                headerStyle={{ height: { base: "40px", md: 14 } }}
                >
                <DropZoneListing />
              </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/dropZoneListing/:id"
          index
          element={
            <ProtectedRoute>
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
              >
              <DropzoneDetails />
            </ClientLayout>
              </ProtectedRoute>
          }
        />
        <Route
          path="/addDropZone"
          index
          element={
                <ProtectedRoute>

            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
              >
              <AddDropZone />
            </ClientLayout>
              </ProtectedRoute>
          }
        />
        <Route
          path="/editDropZone/:id"
          index
          element={
                <ProtectedRoute>

            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
              >
              <EditDropZone />
            </ClientLayout>
              </ProtectedRoute>
          }
        />
        <Route
          path="/paymentLogs"
          index
          element={
            <ProtectedRoute>
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
              >
              <PaymentLogs />
            </ClientLayout>
              </ProtectedRoute>
          }
        />
        <Route
          path="/dropZoneLogs"
          index
          element={
            <ProtectedRoute>
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
              >
              <DropZoneLogs />
            </ClientLayout>
              </ProtectedRoute>
          }
        />
        <Route
          path="/dropZoneLogs/:id"
          index
          element={
            <ProtectedRoute>
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
              >
              <DropZoneLogDetails />
            </ClientLayout>
              </ProtectedRoute>
          }
        />
        <Route
          path="/careers"
          index
          element={
               <ProtectedRoute>
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <Careers />
            </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/careers/:id"
          index
          element={
               <ProtectedRoute>
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <CareersDetails />
            </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/createJob"
          index
          element={
            <ProtectedRoute>
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <CreateJob />
            </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/careers/editcareers/:id"
          index
          element={
            <ProtectedRoute>
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <Editcareers />
            </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/businessHoursManagement"
          index
          element={
            <ProtectedRoute>
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <BusinessHoursManagement />
            </ClientLayout>
              </ProtectedRoute>
          }
        />
        <Route
          path="/pricingManagement"
          index
          element={
            <ProtectedRoute>
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <PricingManagement />
            </ClientLayout>
              </ProtectedRoute>
          }
        />
        <Route
          path="/upcomingEvents"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <UpcomingEvents />
            </ClientLayout>
          }
        />

        <Route
          path="/upcomingEvents/:id"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <UpcomingEventsDetails />
            </ClientLayout>
          }
        />

        <Route
          path="/upcomingEvents/upcomingEventsEdit/:id"
          index
          element={
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <UpcomingEventsEdit />
            </ClientLayout>
          }
        />
        <Route
          path="/upcomingEvents/createEvents"
          index
          element={
                  <ProtectedRoute>
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <CreateEvents />
            </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/instructorsRiggers"
          index
          element={
                  <ProtectedRoute>
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <InstructorsRiggers />
            </ClientLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/instructorsRiggers/:id"
          index
          element={
                  <ProtectedRoute>
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <InstructorsRiggersDetails />
            </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/instructorsRiggers/instructorsRiggersEdit"
          index
          element={
             <ProtectedRoute>
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <InstructorsRiggersEdit />
            </ClientLayout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/instructorsRiggers/instructorsRiggersAdd"
          index
          element={
             <ProtectedRoute>
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <InstructorsRiggersAdd />
            </ClientLayout>
            </ProtectedRoute>
          }
        />

        <Route
          path="/notifications"
          index
          element={
            <ProtectedRoute>
            <ClientLayout
              head={{ title: "Dashboard", description: "Some Description." }}
              headerStyle={{ height: { base: "40px", md: 14 } }}
            >
              <Notifications />
            </ClientLayout>
            </ProtectedRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};
export default MyRouter;
