import { useState } from "react";
import { Col, Row, Card, Form, Input, Button, message, Upload } from "antd";
import DashbordSidebar from "../../components/DashboardSidebar";
import { instructorsData } from "../../components/Data/data";
import { useNavigate, useParams } from "react-router";
import { FaArrowLeftLong } from "react-icons/fa6";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Post } from '../../config/api/post';
import { INSTRUCTOR } from "../../config/constants/api";
import { useSelector } from "react-redux";
import swal from "sweetalert";
const { TextArea } = Input;
dayjs.extend(customParseFormat);


const onFinishFailed = (errorInfo) => {
  console.log("Failed:", errorInfo);
};

const getBase64 = (img, callback) => {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
};
// const beforeUpload = (file) => {
//   const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
//   if (!isJpgOrPng) {
//     message.error("You can only upload JPG/PNG file!");
//   }
//   const isLt2M = file.size / 1024 / 1024 < 2;
//   if (!isLt2M) {
//     message.error("Image must smaller than 2MB!");
//   }
//   return isJpgOrPng && isLt2M;
// };
const beforeUpload = (file) => {
  const isImage = file.type.startsWith("image/");
  if (!isImage) {
    message.error("Invalid Uplaod, You can only upload image files!");
  }
  return isImage;
};
const InstructorsRiggersAdd = () => {
  const [form] = Form.useForm()
  const token = useSelector((state) => state.user.userToken);
  const navigate = useNavigate();
  const { id } = useParams();
  const [imageObject , setImageObject] = useState(null)
  const [instructors, setInstructorsData] = useState(
    instructorsData.find((item) => item.id == id)
  );
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  // const handleChangepro = (info) => {
  //   if (info.file.status === "uploading") {
  //     setLoading(true);
  //     return;
  //   }
  //   if (info.file.status === "done") {
  //     // Get this url from response in real world.
  //     getBase64(info.file.originFileObj, (url) => {
  //       setImageObject(info?.file?.originFileObj)
  //       setLoading(false);
  //       setImageUrl(url);
  //     });
  //   }
  // };
  const handleChangepro = (info) => {
    setLoading(true);
    getBase64(
      info?.fileList[info?.fileList?.length - 1]?.originFileObj,
      (url) => {
        setImageObject(
          info?.fileList[info?.fileList?.length - 1]?.originFileObj
        );
        setLoading(false);
        setImageUrl(url);
      }
    );
  };
  const onFinish = (values) => {
    const {fullName , designation , description , cashappNo , venmoNo} = values
    let data = new FormData()
    data.append('fullName',fullName)
    data.append('designation',designation)
    data.append('description',description)
    data.append('cashappNo',cashappNo)
    data.append('venmoNo',venmoNo)
    data.append('image', imageObject)
    Post(INSTRUCTOR.addInstructor, data , token, null , 'multipart').then((response) => {
      if (response?.status) {
        form.resetFields();
        swal("System Alert!", response?.message, "success");
        navigate("/instructorsRiggers");
      }
    })
    .catch((err) => {
      console.log(err);
      const message = err?.response?.data?.message;
      if (message) {
        swal("Oops", message, "error");
      }
    });
  };
  const uploadButton = (
    <button
      style={{
        border: 0,
        background: "none",
      }}
      type="button"
    >
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </button>
  );
  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <div className="shop-page-main">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={24} lg={24} xl={24}>
                <div className="my-account-profile">
                  <section className="side-menu-parent">
                    <DashbordSidebar />
                    <div className="about-us-section">
                      <div className="bg-paren dashboard-right-card">
                        <Card className="details-card">
                          <div class="arrow-box">
                            <FaArrowLeftLong
                              className="arrow"
                              onClick={() => navigate(-1)}
                            />
                            <h3 className="main-heading">
                              Add Instructor or Rigger
                            </h3>
                          </div>
                          <Row align="" gutter={24}>
                            <Col lg={17}>
                              <Form
                                className="row g-3"
                                name="basic"
                                layout="vertical"
                                initialValues={{
                                  remember: true,
                                }}
                                onFinish={onFinish}
                                autoComplete="off"
                                onFinishFailed={onFinishFailed}
                                form={form}
                              >
                                <Form.Item
                                  label="Full Name:"
                                  name="fullName"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input your Full Name!",
                                    },
                                  ]}
                                >
                                  <Input
                                    size="large"
                                    placeholder="John Doe"
                                    className="web-input"
                                    style={{ maxWidth: "500px" }}
                                  />
                                </Form.Item>
                                <Form.Item
                                  label="Designation:"
                                  name="designation"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input Designation!",
                                    },
                                  ]}
                                >
                                  <Input
                                    size="large"
                                    placeholder="AFF Instructor"
                                    className="web-input"
                                    style={{ maxWidth: "500px" }}
                                  />
                                </Form.Item>
                                <Form.Item
                                  label="Description"
                                  name="description"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input Description!",
                                    },
                                  ]}
                                >
                                  <TextArea
                                    rows={4}
                                    placeholder="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s,"
                                    className="web-input"
                                    style={{ maxWidth: "500px" }}
                                  />
                                </Form.Item>
                                <h3 class="text-20">Tip Details:</h3>
                                <Form.Item 
                                placeholder="Tap to add"
                                label="Cashapp#" name="cashappNo"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please input Cashapp No!",
                                  },
                                ]}
                                >
                                  <Input
                                    size="large"
                                    className="web-input"
                                    style={{ maxWidth: "500px" }}
                                  />
                                </Form.Item>
                                <Form.Item label="Venmo#" name="venmoNo"
                                   placeholder="Tap to add"
                                   rules={[
                                     {
                                       required: true,
                                       message: "Please input Venmo No!",
                                     },
                                   ]}
                                >
                                  <Input
                                    size="large"
                                    className="web-input"
                                    style={{ maxWidth: "500px" }}
                                  />
                                </Form.Item>
                                <div className="frame-group">
                                  <div className="">
                                    <Button
                                      type=""
                                      block
                                      size={"large"}
                                      style={{ margin: "0px 0" }}
                                      className={imageObject ? "web-btn" : "web-btn3"}
                                      htmlType="submit"
                                      disabled = {!imageObject}

                                    >
                                      ADD
                                    </Button>
                                  </div>
                                  <div className="">
                                    <Button
                                      type=""
                                      block
                                      size={"large"}
                                      style={{
                                        marginBottom: "10px",
                                        background: "#b91717",
                                        color: "#fff",
                                      }}
                                      className="web-btn hover-white"
                                      onClick={()=>{navigate(-1)}}
                                    >
                                      Cancel
                                    </Button>
                                  </div>
                                </div>
                              </Form>
                            </Col>
                            <Col lg={7}>
                              <>
                              <Upload
                                name="image"
                                showUploadList={false}
                                style={{ position: "relative" }}
                                onChange={handleChangepro}
                                beforeUpload={beforeUpload}
                              >
                                {" "}
                                <div
                                  style={{
                                    height: "300px",
                                    border: "1px solid gray",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    cursor: "pointer",
                                  }}
                                >
                                  {imageUrl ? (
                                    <img
                                      src={imageUrl}
                                      alt="avatar"
                                      style={{
                                        width: "100%",
                                        height: "300px",
                                        objectPosition: "center",
                                        objectFit: "cover",
                                      }}
                                    />
                                  ) : (
                                    uploadButton
                                  )}
                                </div>{" "}
                              </Upload>

                              </>
                            </Col>
                          </Row>
                        </Card>
                      </div>
                    </div>
                  </section>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default InstructorsRiggersAdd;
