import React, { useEffect, useState } from "react";
import { Col, Row, Card, Image } from "antd";
import DashbordSidebar from "../../components/DashboardSidebar";
import { FaEdit } from "react-icons/fa";
import pricingimg from "../../assets/pricing-img.png";
import { useDispatch, useSelector } from "react-redux";
import { Put } from "../../config/api/put";
import { COMPANY } from "../../config/constants/api";
import swal from "sweetalert";
import { addProfileDetails } from "../../redux/slice/authSlice";
import { useNavigate } from "react-router";

const BusinessHoursManagement = () => {
  const profileDetails = useSelector((state)=>state.user.profileDetails)
  const token = useSelector((state)=>state.user.userToken)
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const [editMode, setEditMode] = useState(false);
  const [prices, setPrices] = useState({
    tandemJump: profileDetails?.pricing?.tandemJump ? profileDetails?.pricing?.tandemJump + '.00' : '00.00',
    soloJump: profileDetails?.pricing?.soloJump ? profileDetails?.pricing?.soloJump + '.00' : '00.00',
    affJump: profileDetails?.pricing?.affJump ? profileDetails?.pricing?.affJump + '.00' : '00.00',
  });

  const handleEdit = () => {
    setEditMode(true);
  };
  useEffect(() => {
    if (!profileDetails) {
      navigate("/create-dropzone-company");
    }
  }, []);
  const handleUpdate = () => {
    let data = new FormData()
    data.append('pricing', JSON.stringify(prices))
    Put(`${COMPANY.updateProfile}${profileDetails?._id}`, token , data).then((response)=>{
      if (response?.status) {
        dispatch(addProfileDetails({details : response?.data}))
        swal("System Alert!", response?.message, "success");
        setEditMode(false);
      }
    }).catch((err)=>{
      console.log(err);
      const message = err?.response?.data?.message;
      if (message) {
        swal("System Alert!", message, "error");
      }
    })
  };

  const handlePriceChange = (event, key) => {
    const { value } = event.target;
    setPrices((prevPrices) => ({
      ...prevPrices,
      [key]: value,
    }));
  };
  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <div className="shop-page-main">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={24} lg={24} xl={24}>
                <div className="my-account-profile">
                  <section className="side-menu-parent">
                    <DashbordSidebar />
                    <div className="about-us-section">
                      <div className="bg-paren dashboard-right-card">
                        <Card className="details-card for-tag-input">
                          <h3 className="heading-28">Pricing Management</h3>
                          <Row
                            align="middle"
                            justify="space-between"
                            gutter={16}
                          >
                            <Col lg={10} xs={24}>
                              <div className="pricing-box">
                                <p className="text-16">Tandem Jump:</p>
                                {editMode ? (
                                  <input
                                    type="text"
                                    className="text-16 price"
                                    value={prices.tandemJump}
                                    onChange={(event) =>
                                      handlePriceChange(event, "tandemJump")
                                    }
                                  />
                                ) : (
                                  <p className="text-16 price">
                                    {'$'+prices.tandemJump}
                                  </p>
                                )}
                                <div style={{cursor : 'pointer'}} className="edit-btn" onClick={handleEdit}>
                                  <FaEdit />
                                </div>
                              </div>
                              <div className="pricing-box">
                                <p className="text-16">Solo Jump:</p>
                                {editMode ? (
                                  <input
                                    type="text"
                                    className="text-16 price"
                                    value={prices.soloJump}
                                    onChange={(event) =>
                                      handlePriceChange(event, "soloJump")
                                    }
                                  />
                                ) : (
                                  <p className="text-16 price">
                                    {'$'+prices.soloJump}
                                  </p>
                                )}
                                <div style={{cursor : 'pointer'}} className="edit-btn" onClick={handleEdit}>
                                  <FaEdit />
                                </div>
                              </div>
                              <div className="pricing-box">
                                <p className="text-16">AFF Jump:</p>
                                {editMode ? (
                                  <input
                                    type="text"
                                    className="text-16 price"
                                    value={prices.affJump}
                                    onChange={(event) =>
                                      handlePriceChange(event, "affJump")
                                    }
                                  />
                                ) : (
                                  <p className="text-16 price">
                                    {'$'+prices.affJump}
                                  </p>
                                )}
                                <div style={{cursor : 'pointer'}} className="edit-btn" onClick={handleEdit}>
                                  <FaEdit />
                                </div>
                              </div>
                              {editMode && (
                                <button
                                  className="update-btn web-btn"
                                  onClick={handleUpdate}
                                >
                                  Update
                                </button>
                              )}
                            </Col>
                            <Col lg={10}>
                              <Image
                                preview={false}
                                alt={"Failed to load image"}
                                src={pricingimg}
                                className=""
                              />
                            </Col>
                          </Row>
                        </Card>
                      </div>
                    </div>
                  </section>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default BusinessHoursManagement;
