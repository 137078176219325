import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Card,
  Form,
  Input,
  DatePicker,
  TimePicker,
  Button,
  AutoComplete,
  Space
} from "antd";
import DashbordSidebar from "../../components/DashboardSidebar";
import { upcomingEventsData } from "../../components/Data/data";
import { useLocation, useNavigate, useParams } from "react-router";
import { FaArrowLeftLong } from "react-icons/fa6";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import moment from "moment";
import AddressApi from "../../config/api/map";
import { useDebouncedCallback } from "use-debounce";
import { Put } from "../../config/api/put";
import { EVENT } from "../../config/constants/api";
import { useSelector } from "react-redux";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

import swal from "sweetalert";
const { TextArea } = Input;
dayjs.extend(customParseFormat);

const onChange = (date, dateString) => {
  console.log(date, dateString);
};

const onFinishFailed = (errorInfo) => {
  console.log("Failed:", errorInfo);
};

const UpcomingEvents = () => {
  const token = useSelector((state) => state.user.userToken);
  const [form] = Form.useForm();
  const [options, setOptions] = useState([]);
  const [query, setQuery] = useState("");
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [address, setAddress] = useState("");
  const [initialDetails, setInitialDetails] = useState([]);
  const onChangeTime = (time, timeString) => {
    console.log(time, timeString)
    // console.log(time);
    // form.setFieldsValue({
    //   time 
    // })
  };
  
  const onFinish = (values) => {
    const { title, date, time, description } = values;
    const formValuesChanged = () => {
      if (!initialDetails) {
        return true;
      }
      return (
        values.title !== initialDetails?.title ||
        values.description !== initialDetails?.description ||
        !values?.date.isSame(initialDetails?.date) ||
        (longitude !== "" && latitude !== "") ||
        !(moment(values.time).format("HH:mm:ss") === initialDetails?.time)
      );
    };
    if (formValuesChanged()) {
      const location = {
        coordinates: [longitude, latitude],
        type: "Point",
        address: address,
      };
      let data = new FormData();
      if (latitude !== "" && longitude !== "") {
        data.append("location", JSON.stringify(location));
      }
      data.append("title", title);
      data.append("description", description);
      data.append("time", moment(time?.$d).format("HH:mm:ss"));
      data.append("date", moment(date?.$d).format("YYYY-MM-DD"));
      Put(`${EVENT.updateEvent}${upcomingEvent?._id}`, token , data)
        .then((response) => {
          if (response.status) {
            swal("System Alert!", response?.message, "success");
            navigate("/upcomingEvents");
          }
        })
        .catch((err) => {
          console.log("Error ", err);
          const message = err?.response?.data?.message;
          if (message) {
            swal("Error", message, "error");
          }
        });
    } else {
      swal("System Alert!", "No changes detected", "error");
      return;
    }
  };
  // const onSelect = (value, options) => {
  //   setQuery(options?.label);
  //   AddressApi.getLatlngByAddress(value, (res) => {
  //     setAddress(res?.results[0]?.formatted_address);
  //     const { lat, lng } = res?.results[0]?.geometry?.location;
  //     setLongitude(lng);
  //     setLatitude(lat);
  //   });
  // };
  // const handleSearch = useDebouncedCallback((text) => {
  //   if (text.length > 0) {
  //     AddressApi.getAddressPrediction(text, (data) => {
  //       if (Array.isArray(data?.predictions)) {
  //         const formattedOptions = data.predictions.map((prediction) => ({
  //           label: prediction.description,
  //           value: prediction.place_id,
  //         }));
  //         setOptions(formattedOptions);
  //       }
  //     });
  //   } else {
  //     setOptions([]);
  //   }
  // }, 1000);
  const handleLocChange = (newAddress) => {
    setAddress(newAddress);
  };
  const handleSelect = (newAddress) => {
    setAddress(newAddress);
    geocodeByAddress(newAddress)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => {
        setLongitude(latLng?.lng);
        setLatitude(latLng?.lat);
      })
      .catch((error) => console.error("Error", error));
  };
  const navigate = useNavigate();
  const location = useLocation();
  const upcomingEvent = location?.state.upcomingEvent;
  const dateConverter = (date) => {
    const convertedDate = new Date(date);
    let options = { year: "numeric",month: "long", day: "numeric", timeZone : 'UTC' };
    let monthAndDate = convertedDate.toLocaleDateString("en-US", options);
    return monthAndDate;
  };
  useEffect(() => {
    if (!upcomingEvent) {
      navigate(-1);
    } else {
      setInitialDetails(upcomingEvent);
      form.setFieldsValue({
        title: upcomingEvent?.title,
        description: upcomingEvent?.description,
        date: dayjs(dateConverter(upcomingEvent?.date)),
        time: moment(upcomingEvent?.time, "HH:mm:ss"),
      });
      setAddress(upcomingEvent?.location?.address);
      setQuery(upcomingEvent?.location?.address);
    }
  }, []);
  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <div className="shop-page-main">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={24} lg={24} xl={24}>
                <div className="my-account-profile">
                  <section className="side-menu-parent">
                    <DashbordSidebar />
                    <div className="about-us-section">
                      <div className="bg-paren dashboard-right-card">
                        <Card className="details-card">
                          <div class="arrow-box">
                            <FaArrowLeftLong
                              className="arrow"
                              onClick={() => navigate(-1)}
                            />
                            <h3 className="main-heading">Edit Event</h3>
                          </div>
                          <Row align="middle" gutter={24}>
                            <Col lg={24}>
                              <Form
                                className="row g-3"
                                name="basic"
                                layout="vertical"
                                initialValues={{
                                  remember: true,
                                }}
                                onFinish={onFinish}
                                autoComplete="off"
                                onFinishFailed={onFinishFailed}
                                form={form}
                              >
                                <Form.Item label="Title Of Event" name="title">
                                  <Input
                                    size="large"
                                    placeholder="ABC Title"
                                    className="web-input"
                                    style={{ maxWidth: "500px" }}
                                  />
                                </Form.Item>
                                <p
                                  style={{ margin: "10px", fontWeight: "bold" }}
                                >
                                  Enter Location
                                </p>
                                {/* <AutoComplete
                                  options={options}
                                  style={{ width: "500px" }}
                                  onSelect={(value, options) => {
                                    onSelect(value, options);
                                  }}
                                  onSearch={(text) => {
                                    handleSearch(text);
                                    setQuery(text);
                                  }}
                                  value={query}
                                  placeholder="Search Location here.."
                                /> */}
                                   <PlacesAutocomplete
                                          value={address}
                                          onChange={handleLocChange}
                                          onSelect={handleSelect}
                                        >
                                          {({
                                            getInputProps,
                                            suggestions,
                                            getSuggestionItemProps,
                                            loading,
                                          }) => {
                                            return (
                                              <div className="location-drop" style={{display:"contents"}}>
                                                <input
                                                  {...getInputProps({
                                                    placeholder:
                                                      "Search location here...",
                                                    className:
                                                      "location-search-input",
                                                    style: {
                                                      position: "relative",
                                                      width: "500px",
                                                      marginBottom: "10px",
                                                      height: "52px",
                                                      borderRadius: "6px",
                                                      border:
                                                        "1px solid #E3E3E3",
                                                      backgroundColor:
                                                        "#F8F8F8",
                                                      padding:
                                                        "10px 50px 10px 10px",
                                                        // display:"contents"
                                                    },
                                                  })}
                                                />
                                                <div className="autocomplete-dropdown-container">
                                                  {loading && (
                                                    <div>Loading...</div>
                                                  )}
                                                  {suggestions.map(
                                                    (suggestion) => {
                                                      const className =
                                                        suggestion.active
                                                          ? "suggestion-item--active googleSuggestion"
                                                          : "suggestion-item googleSuggestion";
                                                      const style =
                                                        suggestion.active
                                                          ? {
                                                              backgroundColor:
                                                                "#fafafa",
                                                              cursor: "pointer",
                                                              padding:
                                                                "5px 10px",
                                                            }
                                                          : {
                                                              backgroundColor:
                                                                "#ffffff",
                                                              cursor: "pointer",
                                                              padding:
                                                                "5px 10px",
                                                            };
                                                      return (
                                                        <div
                                                          {...getSuggestionItemProps(
                                                            suggestion,
                                                            {
                                                              className,
                                                              style,
                                                            }
                                                          )}
                                                        >
                                                          <span>
                                                            {
                                                              suggestion.description
                                                            }
                                                          </span>
                                                        </div>
                                                      );
                                                    }
                                                  )}
                                                </div>
                                              </div>
                                            );
                                          }}
                                        </PlacesAutocomplete>

                                <Form.Item label="Pick a Date" name="date">
                                  <DatePicker
                                    onChange={onChange}
                                    className="web-input"
                                    style={{ maxWidth: "500px" }}
                                  />
                                </Form.Item>
                                <Form.Item label="Pick Time" name="time">
                                  <TimePicker
                                   onChange={onChangeTime}
                                  //  value={moment(upcomingEvent?.time, "HH:mm:ss")}
                                   className="web-input"  
                                   style={{ maxWidth: "500px" }}
                                   />
                                </Form.Item>
                                <Form.Item
                                  label="Description"
                                  name="description"
                                >
                                  <TextArea
                                    rows={4}
                                    placeholder="Enter Description"
                                    maxLength={6}
                                    className="web-input"
                                    style={{ maxWidth: "500px" }}
                                  />
                                </Form.Item>
                                <div className="frame-group">
                                  <div className="">
                                    <Button
                                      type=""
                                      block
                                      htmlType="submit"
                                      size={"large"}
                                      style={{ marginBottom: "10px" }}
                                      className="web-btn"
                                    >
                                      Update
                                    </Button>
                                  </div>
                                  <div className="">
                                    <Button
                                      type=""
                                      block
                                      size={"large"}
                                      style={{
                                        marginBottom: "10px",
                                        background: "#b91717",
                                        color: "#fff",
                                      }}
                                      className="web-btn"
                                      onClick={()=>{navigate(-1)}}
                                    >
                                      Cancel
                                    </Button>
                                  </div>
                                </div>
                              </Form>
                            </Col>
                          </Row>
                        </Card>
                      </div>
                    </div>
                  </section>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default UpcomingEvents;
