import { Col, Row, Card, Form, Input, Button, Select } from "antd";
import { useNavigate } from "react-router";
import DashbordSidebar from "../../components/DashboardSidebar";
import { Post } from "../../config/api/post";
import { JOB } from "../../config/constants/api";
import { useSelector } from "react-redux";
import swal from "sweetalert";

const Calls = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.userToken);
  const validateExperience = (_, value) => {
    if (!value) {
      return Promise.reject("Please input Experience!");
    }
    const regex = /^\d+(-\d+)?$/; 
    if (!regex.test(value)) {
      return Promise.reject("Please enter a valid Experience or range!");
    }

    return Promise.resolve();
  };
  const onFinish = (values) => {
    const {
      jobTitle,
      receivingEmail,
      city,
      state,
      experience,
      minRange,
      maxRange,
      jobRole,
      responsibilities,
      education,
      certification,
      personalAttributes,
      requiredSkills,
      street,
    } = values;
    let data = {
      location: {
        street,
        city,
        state,
      },
      title: jobTitle,
      recEmail: receivingEmail,
      experience,
      minSalary: minRange,
      maxSalary: maxRange,
      jobRole,
      responsibilities,
      education,
      certification,
      attributes: personalAttributes,
      skills: requiredSkills,
    };
    Post(JOB.addJob, data, token)
      .then((response) => {
        if (response?.status) {
          form.resetFields();
          swal("System Alert!", response?.message, "success");
          navigate('/careers')
        }
      })
      .catch((err) => {
        console.log(err);
        const message = err?.response?.data?.message;
        if (message) {
          swal("Oops", message, "error");
        }
      });
  };
  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };


  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <div className="shop-page-main">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={24} lg={24} xl={24}>
                <div className="my-account-profile">
                  <section className="side-menu-parent">
                    <DashbordSidebar />
                    <div className="about-us-section">
                      <div className="bg-paren">
                        <Card className="details-card for-tag-input">
                          <h3 className="heading-28">Create Job</h3>

                          <Form
                            className="row g-3"
                            name="basic"
                            layout="vertical"
                            initialValues={{
                              remember: true,
                            }}
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                            autoComplete="off"
                            form={form}
                          >
                            <Row style={{ width: "100%" }} gutter={[16, 16]}>
                              <Col lg={12} md={12} xs={24}>
                                <Form.Item
                                  label="Job Title"
                                  name="jobTitle"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input Job Title!",
                                    },
                                  ]}
                                >
                                  <Input
                                    size="large"
                                    placeholder="Enter Job Title"
                                    className="web-input"
                                  />
                                </Form.Item>
                              </Col>
                              <Col lg={12} md={12} xs={24}>
                                <Form.Item
                                  label="Resume Receiving Email"
                                  name="receivingEmail"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input Email!",
                                    },
                                  ]}
                                >
                                  <Input
                                    size="large"
                                    placeholder="Enter Resume Receiving Email"
                                    className="web-input"
                                  />
                                </Form.Item>
                              </Col>
                              <Col lg={12} md={12} xs={24}>
                                <Form.Item
                                  label="City"
                                  name="city"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input City!",
                                    },
                                  ]}
                                >
                                  <Input
                                    size="large"
                                    placeholder="Enter City"
                                    className="web-input"
                                  />
                                </Form.Item>
                              </Col>
                              <Col lg={12} md={12} xs={24}>
                                <Form.Item
                                  label="State"
                                  name="state"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input State",
                                    },
                                  ]}
                                >
                                  <Input
                                    size="large"
                                    placeholder="Enter State"
                                    className="web-input"
                                  />
                                </Form.Item>
                              </Col>
                              <Col lg={12} md={12} xs={24}>
                                <Form.Item
                                  label="Street"
                                  name="street"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input Street",
                                    },
                                  ]}
                                >
                                  <Input
                                    size="large"
                                    placeholder="Enter Street"
                                    className="web-input"
                                  />
                                </Form.Item>
                              </Col>
                              <Col lg={12} md={12} xs={24}>
                                <Form.Item
                                  label="Experience"
                                  name="experience"
                                  rules={[
                                    {
                                      validator: validateExperience,
                                    },
                                  ]}
                                >
                                  <Input
                                    size="large"
                                    placeholder="Enter experience in years ( e.g 3 or 3-5 )"
                                    className="web-input"
                                  />
                                </Form.Item>
                              </Col>

                              <Col lg={12} md={12} xs={24}>
                                <Form.Item
                                  label="Salary Min Range"
                                  name="minRange"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input Salary Range!",
                                    },
                                  ]}
                                >
                                  <Input
                                    size="large"
                                    placeholder="Enter Lower range"
                                    className="web-input"
                                    type="number"
                                    inputMode="numeric"
                                  />
                                </Form.Item>
                              </Col>
                              <Col lg={12} md={12} xs={24}>
                                <Form.Item
                                  label="Salary Max range"
                                  name="maxRange"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input Max range",
                                    },
                                  ]}
                                >
                                  <Input
                                    size="large"
                                    placeholder="Enter Max range"
                                    className="web-input"
                                    type="number"
                                    inputMode="numeric"
                                  />
                                </Form.Item>
                              </Col>
                              <Col lg={24} md={24} xs={24}>
                                <Form.Item
                                  label="Job Role"
                                  name="jobRole"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input Job Role",
                                    },
                                  ]}
                                >
                                  <Input
                                    size="large"
                                    placeholder="Write here"
                                    className="web-input"
                                  />
                                </Form.Item>
                              </Col>
                              <Col lg={24} md={24} xs={24}>
                                <Form.Item
                                  label="Key Responsibilities & Accountabilities:"
                                  name="responsibilities"
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Please input Responsibilities & Accountabilities",
                                    },
                                  ]}
                                >
                                  <Select
                                    mode="tags"
                                    style={{
                                      width: "100%",
                                    }}
                                    placeholder="Write here"
                                    className="tag-input"
                                  />
                                </Form.Item>
                              </Col>
                              <Col lg={24} md={24} xs={24}>
                                <Form.Item
                                  label="Education & Experience"
                                  name="education"
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Please input Education & Experience",
                                    },
                                  ]}
                                >
                                  <Select
                                    mode="tags"
                                    style={{
                                      width: "100%",
                                    }}
                                    placeholder="Write here"
                                    className="tag-input"
                                  />
                                </Form.Item>
                              </Col>
                              <Col lg={24} md={24} xs={24}>
                                <Form.Item
                                  label="Certification"
                                  name="certification"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input Certification",
                                    },
                                  ]}
                                >
                                  <Select
                                    mode="tags"
                                    style={{
                                      width: "100%",
                                    }}
                                    placeholder="Write here"
                                    className="tag-input"
                                  />
                                </Form.Item>
                              </Col>
                              <Col lg={24} md={24} xs={24}>
                                <Form.Item
                                  label="Personal Attributes"
                                  name="personalAttributes"
                                  rules={[
                                    {
                                      required: true,
                                      message:
                                        "Please input Personal Attributes",
                                    },
                                  ]}
                                >
                                  <Select
                                    mode="tags"
                                    style={{
                                      width: "100%",
                                    }}
                                    placeholder="Write here"
                                    className="tag-input"
                                  />
                                </Form.Item>
                              </Col>
                              <Col lg={24} md={24} xs={24}>
                                <Form.Item
                                  label="Required Skills"
                                  name="requiredSkills"
                                  rules={[
                                    {
                                      required: true,
                                      message: "Please input Skills",
                                    },
                                  ]}
                                >
                                  <Select
                                    mode="tags"
                                    style={{
                                      width: "100%",
                                    }}
                                    placeholder="Write here"
                                    className="tag-input"
                                  />
                                </Form.Item>
                              </Col>
                              <Col lg={24} md={24} xs={24}>
                                <Button
                                  htmlType="submit"
                                  className="web-btn"
                                >
                                  Publish
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </Card>
                      </div>
                    </div>
                  </section>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Calls;
