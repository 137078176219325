import React, { useEffect, useState } from "react";
import { Col, Row, Card, Button, Spin } from "antd";
import DashbordSidebar from "../../components/DashboardSidebar";
import { useNavigate, useParams } from "react-router";
import { FaArrowLeftLong } from "react-icons/fa6";
import { Get } from "../../config/api/get";
import { INSTRUCTOR, UPLOADS_URL } from "../../config/constants/api";
import { useSelector } from "react-redux";

const InstructorsRiggersDetails = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.user.userToken);
  const { id } = useParams();
  const [instructorDetails, setInstructorDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  const getInstructor = () => {
    setLoading(true);
    Get(`${INSTRUCTOR.getInstructor}${id}`, token)
      .then((response) => {
        if (response?.status) {
          setInstructorDetails(response?.data);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("Error Fetching Instructor Details ", err);
      });
  };
  useEffect(() => {
    if (id) {
      getInstructor();
    } else {
      navigate(-1);
    }
  }, []);
  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <div className="shop-page-main">
            {!loading ? (
              <Row gutter={[16, 16]}>
                <Col xs={24} md={24} lg={24} xl={24}>
                  <div className="my-account-profile">
                    <section className="side-menu-parent">
                      <DashbordSidebar />
                      <div className="about-us-section">
                        <div className="bg-paren dashboard-right-card">
                          <Card className="details-card">
                            <div class="arrow-box">
                              <FaArrowLeftLong
                                className="arrow"
                                onClick={() => navigate(-1)}
                              />
                              <h3 className="heading-28">
                                Instructors & Riggers - Details
                              </h3>
                            </div>
                            <div className="" style={{ textAlign: "end" }}>
                              <Button
                                type=""
                                style={{ marginBottom: "10px" }}
                                className="web-btn"
                                onClick={() =>
                                  navigate(
                                    "/instructorsRiggers/instructorsRiggersEdit", {state : {instructorDetails}}
                                  )
                                }
                              >
                                Edit
                              </Button>
                            </div>
                            <Row
                              justify="center"
                              style={{ alignItems: "baseline" }}
                            >
                              <Col lg={22}>
                                {instructorDetails && (
                                  <Card className="details-card">
                                    <Row style={{ alignItems: "" }}>
                                      <Col lg={8}>
                                        <h3 class="text-20">Name</h3>
                                        <div className="jump-inner-detail">
                                          <div className="for-media-object instructorImg">
                                            <span>
                                              {" "}
                                              <img
                                                src={
                                                  UPLOADS_URL +
                                                  instructorDetails?.image
                                                }
                                                alt=""
                                                className="img-fluid"
                                                style={{ width: "100%" }}
                                              />
                                            </span>
                                            <div>
                                              <h6 class="instructor-name">
                                                {instructorDetails?.fullName}
                                              </h6>
                                              <span class="blue-text">
                                                {instructorDetails?.designation}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </Col>
                                      <Col lg={16}>
                                        <h3 class="text-20">Description</h3>
                                        <div className="jump-inner-detail">
                                          <div className="for-media-object">
                                            <p className="web-p">
                                              {instructorDetails?.description}
                                            </p>
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>

                                    <Row style={{ alignItems: "" }}>
                                      <Col lg={18}>
                                        <h3 class="text-20">Tip Details:</h3>
                                        <div className="jump-inner-detail">
                                          <div>
                                            <h6 class="instructor-name">
                                              Cashapp # 
                                              <span className="blue-text">
                                                {instructorDetails?.cashappNo}
                                              </span>
                                            </h6>
                                            <h6 class="instructor-name">
                                              Venmo #
                                              <span className="blue-text">
                                                {instructorDetails?.venmoNo}
                                              </span>
                                            </h6>
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Card>
                                )}
                              </Col>
                            </Row>
                          </Card>
                        </div>
                      </div>
                    </section>
                  </div>
                </Col>
              </Row>
            ) : (
              <Card>
                <Col
                  xs={23}
                  md={21}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "150px 0px",
                  }}
                >
                  <Spin />
                </Col>
              </Card>
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default InstructorsRiggersDetails;
