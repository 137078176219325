import React, { useEffect, useState } from "react";
import { Col, Row, Card, Spin } from "antd";
import DashbordSidebar from "../../components/DashboardSidebar";
import { upcomingEventsData } from "../../components/Data/data";
import { useNavigate, useParams } from "react-router";
import { FaArrowLeftLong } from "react-icons/fa6";
import { Get } from "../../config/api/get";
import { EVENT } from "../../config/constants/api";
import { useSelector } from "react-redux";

const dateConverter = (date) => {
  const convertedDate = new Date(date);
  let options = { month: "long", day: "numeric", timeZone: "UTC" };
  let monthAndDate = convertedDate.toLocaleDateString("en-US", options);
  return monthAndDate;
};

const UpcomingEvents = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const token = useSelector((state) => state.user.userToken);

  const getEventDetails = () => {
    setLoading(true)
    Get(`${EVENT.getevent}${id}`, token)
      .then((response) => {
        if (response?.status) {
          setLoading(false);
          setEventDetails(response?.data);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log("Error fetching details ", err);
      });
  };
  useEffect(() => {
    getEventDetails();
  }, []);
  const [eventDetails, setEventDetails] = useState(null);
  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <div className="shop-page-main">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={24} lg={24} xl={24}>
                <div className="my-account-profile">
                  <section className="side-menu-parent">
                    <DashbordSidebar />
                    <div className="about-us-section">
                      <div className="bg-paren dashboard-right-card">
                        {!loading ? (
                          <Card className="details-card">
                            <div class="arrow-box">
                              <FaArrowLeftLong
                                className="arrow"
                                onClick={() => navigate(-1)}
                              />
                              <h3 className="main-heading">Event Details</h3>
                            </div>
                            {eventDetails && (
                              <Row align="middle" gutter={24}>
                                <Col lg={24}>
                                  <div
                                    className=" event-date-box"
                                    style={{
                                      textAlign: "center",
                                      marginBottom: "20px",
                                    }}
                                  >
                                    <h6>
                                      {dateConverter(eventDetails?.date)
                                        .split(" ")
                                        .map((part, index) => (
                                          <React.Fragment key={index}>
                                            {index > 0 && <br />} {part}
                                          </React.Fragment>
                                        ))}
                                    </h6>
                                  </div>
                                </Col>
                                <Col lg={24}>
                                  <div style={{ textAlign: "center" }}>
                                    <h5 className="text-22-urb">
                                      {eventDetails?.title}
                                    </h5>
                                    <p className="web-p">
                                      {eventDetails.description}
                                    </p>
                                  </div>
                                </Col>
                              </Row>
                            )}
                          </Card>
                        ) : (
                          <Card>
                            <Col
                              xs={23}
                              md={21}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "150px 0px",
                              }}
                            >
                              <Spin />
                            </Col>
                          </Card>
                        )}
                      </div>
                    </div>
                  </section>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default UpcomingEvents;
